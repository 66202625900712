import * as React from "react"

const Subheading = ({ text, customClass }) => {
    if (!customClass)  {
        customClass = "sm:text-[30px] mb-6"
    }
    return (
        <h3
            className={`
            font-medium
            text-2xl
            ${customClass}`}
        >{text}
        </h3>
    )
}

export default Subheading