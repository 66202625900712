import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import ScrollAnimation from 'react-animate-on-scroll';

// Components
import Heading from "./heading"
import Subheading from "./subheading"
import Paragraph from "./paragraph"
import List from "./list"
import Button from "./button"

const Story = ({ heading, subheading, text, image, imageAlt, bullets, button, reverse, imgHero, accent, ext }) => {
    return (
        <div className={`flex flex-col items-center xl:items-start justify-between gap-y-20 ${reverse ? 'xl:flex-row' : 'xl:flex-row-reverse'}`}>
            <div className={`text-center xl:text-left ${imgHero ? 'flex-[0_1_35%]' : 'flex-1'}`}>
                    <Heading
                        text={heading}
                    />
                {subheading &&
                <Subheading
                    text={subheading}
                />}
                <Paragraph
                    text={text}
                    accent={accent}
                />
                {bullets && 
                <List reverse={reverse} list={bullets}/>}
                {button &&
                <Button ext={ext} accent={accent} text={button.text} url={button.url}/>}
            </div>
            <div className={`flex flex-1 ${reverse ? 'justify-end' : 'justify-start'}`}>
            <ScrollAnimation animateIn="bounceInUp" duration={1.5} animateOnce>
                <GatsbyImage
                    image={image.childImageSharp.gatsbyImageData}
                    alt={imageAlt}
                    className={`max-w-[820px]`}
                />
            </ScrollAnimation>
            </div>
        </div>
    )
}

export default Story