import * as React from "react"

const Heading = ({ text, customClass }) => {
    if (!customClass)  {
        customClass = 'mb-8'
    }
    return (
        <h2
            className={`
            font-medium
            text-2xl
            lg:text-[70px]
            sm:leading-[1.4]
            ${customClass}
            `}
        >{text}
        </h2>
    )
}

export default Heading