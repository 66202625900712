import * as React from "react"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import ScrollAnimation from 'react-animate-on-scroll';

const List = ({ list, reverse }) => {
    const LinkSetup = ({ url, children }) => {
        if (url?.includes('https') || url?.includes('www')) {
            return (
                <a className="underline" href={url} target="_blank" rel="noreferrer">
                    {children}
                </a>
            )
        } else if (url?.includes('#')) {
            return (
                <AnchorLink to={url} title="Join our podcast"
                className="underline" />
            )
        }
        else {
            return (
                <Link className="underline" to={url}>
                    {children}
                </Link>
            )
        }
    }
    const Bullets = () => {
        let delay = 200
        return (
            <ul className="list-none mt-14">
            {list.map((item, index) => {
                if (index > 0) {
                    delay += 200
                }
                return (
                <ScrollAnimation delay={delay} animateIn={reverse ? "fadeInLeft" : "fadeInRight"} animateOnce key={index} 
                className="
                flex flex-row 
                items-center  
                justify-center sm:justify-start
                text-base 
                sm:text-2xl
                mb-6
                ">
                    <img className="w-[30px] h-[30px] mr-3 sm:mr-6" src={item.icon.publicURL} alt=""/>
                    <LinkSetup url={item.url}>
                        {item.text}
                    </LinkSetup>
                </ScrollAnimation>

                )
            })}
            </ul>
        )
    }
    return (
        <Bullets/>
    )
}

export default List